import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const work = [
  {
    title: 'DISNEY+ Talk Show',
    client: 'LADbible',
    id: '01',
    links: [
      // {
      //   title: 'View Project',
      //   url: 'https://www.google.com',
      //   text: 'View Project',
      // },
    ],
    blurb: '<p>Art Direction and Set Design, four-part talk show.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/set-design/disney-1.jpg"
          alt="LADbible - DISNEY+ Talk Show - set direction by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/set-design/disney-2.jpg"
          alt="LADbible - DISNEY+ Talk Show - set direction by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/set-design/disney-3.jpg"
          alt="LADbible - DISNEY+ Talk Show - set direction by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/set-design/disney-4.jpg"
          alt="LADbible - DISNEY+ Talk Show - set direction by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/set-design/disney-5.jpg"
          alt="LADbible - DISNEY+ Talk Show - set direction by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Art Direction', 'Set Design', 'Featured'],
  },
  {
    title: 'Royal Ascot',
    client: 'Ascot Racecourse',
    id: '02',
    links: [],
    blurb:
      '<p>Design and build of public features including bars, photo opportunities, stages, fine dining restaurants and creation of bespoke artwork.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/design build/Royal Ascot/Royal Ascot 1.jpg"
          alt="Royal Ascot public features design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/design build/Royal Ascot/Royal Ascot 2.jpg"
          alt="Royal Ascot public features design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/design build/Royal Ascot/Royal Ascot 3.jpg"
          alt="Royal Ascot public features design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/design build/Royal Ascot/Royal Ascot 4.jpg"
          alt="Royal Ascot public features design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/design build/Royal Ascot/Royal Ascot 5.jpg"
          alt="Royal Ascot public features design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Design / Build', 'Featured'],
  },
  {
    title: 'Food Station',
    client: 'Imperial War Museum',
    id: '03',
    links: [],
    blurb: '<p>Interactive catering station; make your own Knickerbocker Glory.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/design build/Food Station/Food Station 1.jpg"
          alt="Imerial War Museum design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/design build/Food Station/Food Station 2.jpg"
          alt="Imerial War Museum design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/design build/Food Station/Food Station 3.jpg"
          alt="Imerial War Museum design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/design build/Food Station/Food Station 4.jpg"
          alt="Imerial War Museum design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Design / Build', 'Featured'],
  },
  {
    title: 'KFC Boats',
    client: 'LADbible',
    id: '04',
    links: [],
    blurb: '<p>Boats designed to look like KFC gravy pots.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/design build/KFC Boats/KFC Boats 1.jpg"
          alt="KFC Boats - design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/design build/KFC Boats/KFC Boats 2.jpg"
          alt="KFC Boats - design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/design build/KFC Boats/KFC Boats 3.jpg"
          alt="KFC Boats - design and build by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Design / Build', 'Featured'],
  },
  {
    title: 'The Social',
    client: 'LADbible',
    id: '05',
    links: [],
    blurb: '<p>Set design and Art Direction for 3 Seasons of the The Social, in partnership with Brew City and Currys.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Film/The Social/The Social 1.jpg"
          alt="Set design and Art Direction for LADbible by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/The Social/The Social 2.jpg"
          alt="Set design and Art Direction for LADbible by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/The Social/The Social 3.jpg"
          alt="Set design and Art Direction for LADbible by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Film', 'Art Direction', 'Set Design'],
  },

  {
    title: 'Lightyear',
    client: 'Telly Juice',
    id: '06',
    links: [],
    blurb: '<p>Art Direction for Lightyear themed branded content with Tom Fletcher, in conjunction with Smyths Toys Superstores.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Film/Lightyear/Lightyear 1.jpg"
          alt="Set design and Art Direction for LADbible by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      [
        //Main image
        () => (
          //html video element
          <video autoPlay playsInline controls src="/video/Lightyear Video.mp4" alt="Set design and Art Direction for LADbible by Victoria Owen" />
        ),
        //Thumnail
        () => (
          <StaticImage
            src="../images/work/Film/Lightyear/Lightyear 2.jpg"
            alt="Set design and Art Direction for LADbible by Victoria Owen"
            objectFit="contain"
            objectPosition={'center right'}
          />
        ),
      ],
    ],
    categories: ['Film', 'Art Direction', 'Set Design'],
  },

  {
    title: 'Larping',
    client: 'Jungle Creations',
    id: '07',
    links: [],
    blurb: '<p>Art Direction for 3 Episodes of Go a bit Peperami with Yung Filly and Peperami TV.</p><p>Photography Credits Ryan O’Donoghue.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Film/Larping/Larping 1.jpg"
          alt="Set design and Art Direction for Jungle Creations by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/Larping/Larping 2.jpg"
          alt="Set design and Art Direction for Jungle Creations by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/Larping/Larping 3.jpg"
          alt="Set design and Art Direction for Jungle Creations by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/Larping/Larping 4.jpg"
          alt="Set design and Art Direction for Jungle Creations by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Film', 'Art Direction', 'Set Design'],
  },

  {
    title: 'Spencer FC',
    client: 'Spencer FC',
    id: '08',
    links: [],
    blurb: '<p>Transforming Spencer Owen’s empty space into a chat show studio set, reminiscent of a football Chairman’s office.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Film/Spencer FC/Spencer FC 1.jpg"
          alt="Set design and Art Direction for Spencer Owen by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/Spencer FC/Spencer FC 2.jpg"
          alt="Set design and Art Direction for Spencer Owen by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/Spencer FC/Spencer FC 3.jpg"
          alt="Set design and Art Direction for Spencer Owen by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Film', 'Art Direction', 'Set Design'],
  },

  {
    title: 'Lynx Campaigns',
    client: 'LADbible',
    id: '09',
    links: [],
    blurb: '<p>Art Direction and Prop Making for two differing Lynx Campaigns.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Film/Lynx/Lynx 1.jpg"
          alt="Set design and Art Direction for Lynx by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/Lynx/Lynx 2.jpg"
          alt="Set design and Art Direction for Lynx by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/Lynx/Lynx 3.jpg"
          alt="Set design and Art Direction for Lynx by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),

      () => (
        <StaticImage
          src="../images/work/Film/Lynx/Lynx 4.jpg"
          alt="Set design and Art Direction for Lynx by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Film', 'Art Direction', 'Set Design'],
  },

  {
    title: 'DIY Duvet Day',
    client: 'LADbible',
    id: '10',
    links: [],
    blurb: '<p>Art Direction and Set Design for the ultimate duvet day.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Film/Duvet Day/Duvet Day 1.jpg"
          alt="Set design and Art Direction for LADbible by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/Duvet Day/Duvet Day 2.jpg"
          alt="Set design and Art Direction for LADbible by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Film/Duvet Day/Duvet Day 3.jpg"
          alt="Set design and Art Direction for LADbible by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Film', 'Art Direction', 'Set Design'],
  },

  {
    title: 'Product Launches',
    client: 'HP',
    id: '11',
    links: [],
    blurb:
      '<p>Creation of a lounge environment in a London Eye pod for the launch of an HP printer.</p><p>Set design and styling for the launch of an HP laptop.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Product Launches/London Eye 1.jpg"
          alt="Product launch for HP by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Product Launches/London Eye 2.jpg"
          alt="Product launch for HP by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Product Launches/HP 3.jpg"
          alt="Product launch for HP by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Product Launches/HP 4.jpg"
          alt="Product launch for HP by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Experiential Design'],
  },

  {
    title: 'Restaurant Designs',
    client: 'Ascot Racecourse',
    id: '18',
    links: [],
    blurb: '<p>Design and fit out for fine dining restaurants at Royal Ascot.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Restaurant Designs/Restaurant Designs 1.jpg"
          alt="Restaurant Designs for Royal Ascot by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Restaurant Designs/Restaurant Designs 2.jpg"
          alt="Restaurant Designs for Royal Ascot by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Restaurant Designs/Restaurant Designs 3.jpg"
          alt="Restaurant Designs for Royal Ascot by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Restaurant Designs/Restaurant Designs 4.jpg"
          alt="Restaurant Designs for Royal Ascot by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Experiential Design'],
  },

  {
    title: 'Money2020 Europe',
    client: 'Ascential',
    id: '12',
    links: [],
    blurb: '<p>Design and build of feature areas, 2018, 2019 and 2022.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Money2020/Money2020 1.jpg"
          alt="Feature Area design for Money Ascential by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Money2020/Money2020 2.jpg"
          alt="Feature Area design for Money Ascential by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Money2020/Money2020 3.jpg"
          alt="Feature Area design for Money Ascential by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Money2020/Money2020 4.jpg"
          alt="Feature Area design for Money Ascential by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Money2020/Money2020 5.jpg"
          alt="Feature Area design for Money Ascential by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Money2020/Money2020 6.jpg"
          alt="Feature Area design for Money Ascential by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Experiential Design'],
  },

  {
    title: 'Immersive Set Design',
    client: 'Various',
    id: '13',
    links: [],
    blurb: '<p>Dia De Los Muertos inspired event, Charity Fundraiser Jersey Boys set, Gala Ball.</p><p>Save the Children, Charity Fundraisers.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Immersive Set Design/Immersive Set Design 1.jpg"
          alt="Immersive set design by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Immersive Set Design/Immersive Set Design 2.jpg"
          alt="Immersive set design by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Immersive Set Design/Immersive Set Design 3.jpg"
          alt="Immersive set design by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Immersive Set Design/Immersive Set Design 4.jpg"
          alt="Immersive set design by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Experiential Design/Immersive Set Design/Immersive Set Design 5.jpg"
          alt="Immersive set design by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Experiential Design'],
  },
  {
    title: 'Penis Wall',
    client: 'Durex',
    id: '14',
    links: [],
    blurb: '<p>Design and build of Penis Wall for Durex campaign in partnership with LADbible.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Sculpture/Penis Wall/Penis Wall 1.jpg"
          alt="Penis Sculpture wall for Durex by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Penis Wall/Penis Wall 2.jpg"
          alt="Penis Sculpture wall for Durex by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Penis Wall/Penis Wall 3.jpg"
          alt="Penis Sculpture wall for Durex by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Sculpture'],
  },

  {
    title: 'Rigged Chandeliers',
    client: 'Ascot Racecourse',
    id: '15',
    links: [],
    blurb: '<p>Design, creation and installation of varying chandeliers installed at Ascot Racecourse and Money2020 Europe for Ascential.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Sculpture/Rigged Chandeliers/Rigged Chandeliers 1.jpg"
          alt="Rigged chandeliers at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Rigged Chandeliers/Rigged Chandeliers 2.jpg"
          alt="Rigged chandeliers at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Rigged Chandeliers/Rigged Chandeliers 3.jpg"
          alt="Rigged chandeliers at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Rigged Chandeliers/Rigged Chandeliers 4.jpg"
          alt="Rigged chandeliers at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Rigged Chandeliers/Rigged Chandeliers 5.jpg"
          alt="Rigged chandeliers at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Sculpture'],
  },
  {
    title: 'Floral Sculptural Installations',
    client: 'Ascot Racecourse',
    id: '16',
    links: [],
    blurb: '<p>Design, creation and installation of varying floral inspired sculptural installations.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Sculpture/Floral Sculptural Installations/Floral Sculptural Installations 1.jpg"
          alt="Floral Sculptures at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Floral Sculptural Installations/Floral Sculptural Installations 2.jpg"
          alt="Floral Sculptures at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Floral Sculptural Installations/Floral Sculptural Installations 3.jpg"
          alt="Floral Sculptures at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Floral Sculptural Installations/Floral Sculptural Installations 4.jpg"
          alt="Rigged chandeliers at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Floral Sculptural Installations/Floral Sculptural Installations 5.jpg"
          alt="Floral Sculptures at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Sculpture'],
  },
  {
    title: 'Horse Sculpture',
    client: 'Ascot Racecourse',
    id: '17',
    links: [],
    blurb: '<p>Design, creation and installation of horse sculpture for Ascot Racecourse.</p>',
    images: [
      () => (
        <StaticImage
          src="../images/work/Sculpture/Horse Sculpture/Horse Sculpture 1.jpg"
          alt="Horse sculpture at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Horse Sculpture/Horse Sculpture 2.jpg"
          alt="Horse sculpture at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Horse Sculpture/Horse Sculpture 3.jpg"
          alt="Horse sculpture at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
      () => (
        <StaticImage
          src="../images/work/Sculpture/Horse Sculpture/Horse Sculpture 4.jpg"
          alt="Horse sculpture at Ascot Racecourse by Victoria Owen"
          objectFit="contain"
          objectPosition={'center right'}
        />
      ),
    ],
    categories: ['Sculpture'],
  },
];
