import React, { useState } from 'react';
import Seo from '../components/Seo';
import { workHolder } from './work.module.scss';
import { work } from '../data/data.js';
import { AnimatePresence, motion } from 'framer-motion';

const featuredImageVariants = {
  initial: {
    opacity: 0,
    scale: 0.98,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: [0.6, 0.01, -0.05, 0.9],
    },
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    transition: {
      duration: 0.5,
      ease: [0.6, 0.01, -0.05, 0.9],
    },
  },
};

const FeaturedImage = ({ img }) => {
  const Img = img instanceof Array ? img[0] : img;
  return <Img />;
};

const Slide = ({ img }) => {
  const Img = img instanceof Array ? img[1] : img;

  return <Img />;
};

const Slides = ({ images, setIndex }) => {
  return (
    <ul className="slides">
      {images.map((img, i) => {
        return (
          <li key={i}>
            <button
              onClick={() => {
                setIndex(i);
              }}>
              <Slide img={img} />
            </button>
          </li>
        );
      })}
    </ul>
  );
};

const Work = ({ work }) => {
  const [index, setIndex] = useState(0);

  return (
    <li className="workItem">
      <article className="lrHolder">
        <div className="lhs">
          <AnimatePresence mode="wait">
            <motion.div key={index} variants={featuredImageVariants} initial="initial" animate="animate" exit="exit" className="imageHolder">
              <FeaturedImage img={work.images[index]} />
            </motion.div>
          </AnimatePresence>
        </div>

        <div className="rhs">
          <div className="inner">
            <header>
              <h3>{work.title}</h3>
              <div className="client">{work.client}</div>
            </header>
            <div className="blurb" dangerouslySetInnerHTML={{ __html: work.blurb }} />
            <Slides images={work.images} setIndex={setIndex} />
            {work.links.length > 0 && (
              <ul className="workLinks">
                {work.links.map((link) => {
                  return (
                    <li key={link.url}>
                      <a href={link.url} title={link.title}>
                        {link.text}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </article>
    </li>
  );
};

export default function WorkPage() {
  const [selected, setSelected] = useState(null);

  const selectedWork = selected ? work.filter((item) => item.categories.includes(selected)) : work;

  return (
    <article className={workHolder}>
      <header className="pageHeader">
        <h1>Work</h1>
        <nav>
          <ul className="categories">
            <li className={`${!selected ? 'selected' : ''}`}>
              <button onClick={() => setSelected(null)}>
                <span>All Work</span>
              </button>
            </li>
            <li className={`${selected === 'Film' ? 'selected' : ''}`}>
              <button onClick={() => setSelected('Film')}>
                <span>Film</span>
              </button>
            </li>
            <li className={`${selected === 'Experiential Design' ? 'selected' : ''}`}>
              <button onClick={() => setSelected('Experiential Design')}>
                <span>Experiential Design</span>
              </button>
            </li>
            <li className={`${selected === 'Sculpture' ? 'selected' : ''}`}>
              <button onClick={() => setSelected('Sculpture')}>
                <span>Sculpture</span>
              </button>
            </li>
          </ul>
        </nav>
      </header>
      <section>
        <ul className="work">
          {
            // Loop through the work data and output the Work component
            selectedWork.map((work) => (
              <Work work={work} key={work.id} />
            ))
          }
        </ul>
      </section>
    </article>
  );
}

export const Head = () => <Seo title="Art direction and creative design by Victoria Owen" />;
